<template>
    <!--
    <a-breadcrumb :style="{ margin: '16px 0' }">
      <a-breadcrumb-item>
        <router-link :to="'home'">首页</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item v-if="this.$route.name == 'chapters'">{{this.$route.query.name}}</a-breadcrumb-item>
      <a-breadcrumb-item v-if="this.$route.name == 'content'">
        <router-link
          v-bind:to="{name:'chapters', query:{ id: this.$route.query.id, name: this.$route.query.name }}"
        >{{this.$route.query.name}}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item v-if="this.$route.name == 'content'">正文</a-breadcrumb-item>
    </a-breadcrumb>
    -->
    <div>
      <a-list v-if="data.length > 0" :dataSource="data" style="padding:0px;margin:0px">
        <a-list-item slot="renderItem" slot-scope="item, index">
          <a-row style="width:100%;">
            <a-col :span="18">
              {{index+1}}、
              <router-link
                :to="{ name:'chapters', query:{ id:item.id, name:item.name } }"
                @click.native="setCurrNovel(index)"
              >{{ item.name }}</router-link>
            </a-col>
            <a-col :span="6" :style="{ textAlign:'right' }">
              <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link" href="#">
                  Actions
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item @click="handleEdit(item.id)">
                    <a-icon type="edit" />编辑
                  </a-menu-item>
                  <a-menu-item>
                    <a-popconfirm
                      placement="leftTop"
                      title="确定要删除吗？"
                      @confirm="handleDelete(item.id,index)"
                      @cancel="cancel"
                      okText="是的"
                      cancelText="取消"
                    ><a-icon type="delete" />删除
                    </a-popconfirm>
                  </a-menu-item>
                  <a-menu-divider />
                  <a-menu-item @click="openWindow('https://www.baidu.com/s?wd='+item.name)">
                    <a-icon type="search" />百度
                  </a-menu-item>
                  <a-menu-item @click="openWindow(item.url)">
                    <a-icon type="link" />地址
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-col>
          </a-row>
        </a-list-item>
      </a-list>
      <a-skeleton v-if="loading" active />
  </div>
</template>
<script>
const base_API_URL = '/api/novel/getAllNovels'

export default {
  name: 'novel',
  props: ['novelData'],
  data() {
    return { data: [], loading: false, busy: false }
  },
  components: {},
  /*
    watch:{
      fields:{
        handler(val, oldVal){
          console.log(val);
        },
        deep:true
      }
    },
    */
  beforeMount() {
    this.fetchData()
  },
  watch: {
    /*
      //监视props参数novelData
      novelData(data) {
       this.data.push(data);
      }
      */
  },
  methods: {
    openWindow(url) {
      window.open(url)
    },
    setCurrNovel(index) {
      this.$store.commit('setNovel', this.data[index])
      /*
        localStorage.setItem('Current',
          this.data.filter((x) => {
            return JSON.stringify(x.id == this.data[index].id);
          }));
          */
    },
    cancel() {
      this.$message.warning('删除操作已取消！')
    },
    fetchData(url) {
      this.loading = true
      this.$axios
        .get(base_API_URL, {
          params: {},
          headers: {
            Authorization: this.$store.state.appCode
          }
        })
        .then(res => {
          this.data = res.data.data
          this.loading = false
        })
        .catch(error => {
          console.warn(error)
        })
    },
    handleButtonClick({ key }) {
      console.log(key)
    },
    handleEdit(id) {
      this.$store.commit('setEditFormVisible', true)
      const base_API_URL = '/api/novel/getNovel'

      this.$axios
        .get(base_API_URL, { params: { id: id } })
        .then(res => {
          this.$store.commit('setNovel', res.data.data)
        })
        .catch(error => {
          console.warn(error)
          console.log('error')
          this.$message.error('发生错误！')
        })
    },
    handleDelete(id, index) {
      var deleteNovelAPI = '/api/novel/deleteNovel'
      this.$axios
        .delete(deleteNovelAPI, { params: { id: id } })
        .then(res => {
          sessionStorage.removeItem(id)
          this.data.splice(index, 1)
          this.$message.success('小说删除成功！')
          this.$router.push(
            { path: 'home' },
            onComplete => {},
            onAbort => {}
          )
        })
        .catch(error => {
          console.warn(error)
          this.$message.error('发生错误！')
        })
    }
    /*
      handleInfiniteOnLoad() {
        const data = this.data;
        this.loading = true;
        if (data.length > 14) {
          this.$message.warning('Infinite List loaded all');
          this.busy = true;
          this.loading = false;
          return;
        }
        this.fetchData(res => {
          this.data = data.concat(res.results);
          console.log('dddd');
          this.loading = false;
        });
      },
      */
  }
}
</script>
<style>
.alignRight {
  text-align: right;
}
.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 15px;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
</style>